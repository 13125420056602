import React, { FC, useEffect, useMemo } from 'react';

import {
    PurchaseOrderType,
    SupplierDetailDto,
    useSupplierDetailsQuery,
    useVariantsWithProductAndPrice,
    VariantWithProductAndPriceMap,
    VariantWithProductDto,
} from '@hofy/api-admin';
import {
    Currency,
    isPositive,
    isPriceGreaterThan,
    isPurchaseTaxReclaimableCountry,
    minusPrice,
    numberToPercent,
    percentToNumber,
    Price,
    priceToNumber,
    sumPrice,
    UUID,
    VisualType,
    zeroPercent,
    zeroPrice,
} from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    ArrayField,
    Box,
    Button,
    CompactAlert,
    FormContainer,
    FormDateInput,
    FormDecimalInput,
    FormFieldRecord,
    FormGridRow,
    FormInput,
    FormNumberInput,
    FormPriceInput,
    IconButton,
    LabeledInput,
    SectionTitle2,
    Skeleton,
    SvgIcon,
    UseForm,
    useFormArrayField,
} from '@hofy/ui';

import {
    emptyPurchaseOrderItemData,
    PurchaseOrderFormItemData,
    PurchaseOrderItemsFormData,
} from '../../../../../store/purchaseOrders/usePurchaseOrderItemsForm';
import { PurchaseOrderSummaryLine } from './PurchaseOrderSummaryLine';
import { VariantSelect } from './VariantSelect';

interface PurchaseOrderItemsFormProps {
    itemsForm: UseForm<PurchaseOrderItemsFormData>;
    currency: Currency;
    supplierId: UUID | null;
    isDropship: boolean;
}

export const PurchaseOrderItemsForm: FC<PurchaseOrderItemsFormProps> = ({
    itemsForm,
    currency,
    supplierId,
    isDropship,
}) => {
    const { data: supplier, isLoading: isLoadingSupplier } = useSupplierDetailsQuery(supplierId);
    const { isLoading: isLoadingVariants, variantsById } = useVariantsWithProductAndPrice(
        {
            country: supplier?.billingAddress.country!,
            currency: currency,
            purchaseOrderType: isDropship ? PurchaseOrderType.Dropship : PurchaseOrderType.Warehouse,
        },
        supplier !== undefined,
    );

    const items = useFormArrayField(itemsForm.fields.items, emptyPurchaseOrderItemData(currency));
    useEffect(() => {
        itemsForm.fields.totalHandlingCost.setValue({
            amount: itemsForm.values.totalHandlingCost.amount,
            currency: currency,
        });

        const newItems = itemsForm.fields.items.value.map(item => {
            return {
                ...item,
                unitNetPrice: {
                    amount: item.unitNetPrice.amount,
                    currency: currency,
                },
            };
        });
        itemsForm.fields.items.setValue(newItems);
    }, [currency]);

    const { totalOrderNetValue, totalTaxValue, totalOrderValue } = useMemo(
        () => calculateTotals(items, itemsForm, currency),
        [items],
    );

    if (isLoadingSupplier || isLoadingVariants) {
        return <Skeleton height={400} />;
    }

    return (
        <FormContainer marginTop={10} marginBottom={10}>
            {items.fields.map(({ key, api }) => (
                <PurchaseOrderItemRow
                    key={key}
                    index={key}
                    api={api}
                    items={items}
                    currency={currency}
                    supplier={supplier}
                    isDropship={isDropship}
                    variantsById={variantsById}
                />
            ))}

            {!isDropship && (
                <Button
                    leftIcon={SvgIcon.Add}
                    label='Add item'
                    type='secondary'
                    onClick={() => {
                        items.add();
                    }}
                />
            )}

            <PurchaseOrderSummaryLine label='Total net value:' value={totalOrderNetValue} />
            <PurchaseOrderSummaryLine label='Total tax value:' value={totalTaxValue} />

            <Box
                paddingVertical={10}
                paddingHorizontal={10}
                flex='auto'
                direction='row'
                bg={Color.BackgroundDefault}
                border
                rounded
            >
                <Box column paddingHorizontal={15} flex={1}>
                    <SectionTitle2 color={Color.Neutral500}>Shipping/handling cost:</SectionTitle2>
                </Box>
                <FormPriceInput api={itemsForm.fields.totalHandlingCost} label='' />
            </Box>

            <PurchaseOrderSummaryLine
                label='Total order value:'
                subLabel='Incl. tax and shipping cost'
                value={totalOrderValue}
            />
        </FormContainer>
    );
};

interface PurchaseOrderItemRowProps {
    api: FormFieldRecord<PurchaseOrderFormItemData>;
    items: ArrayField<PurchaseOrderFormItemData>;
    currency: Currency;
    isDropship: boolean;
    variantsById: Record<UUID, VariantWithProductDto>;
    supplier?: SupplierDetailDto;
    index: string;
}

const PurchaseOrderItemRow: FC<PurchaseOrderItemRowProps> = ({
    api,
    items,
    currency,
    supplier,
    isDropship,
    variantsById,
    index,
}) => {
    const { formatPrice } = usePrice();
    const totalGrossPrice = useMemo(() => calculateItemGrossPrice(api, currency), [api, currency]);
    const priceAndTargetState = getPriceAndTargetState({
        variantsById,
        supplier: supplier || null,
        unitNetPrice: api.unitNetPrice.value,
        totalGrossPrice: totalGrossPrice,
        quantity: api.quantity.value || 0,
        variantId: api.variantId.value,
    });

    useEffect(() => {
        if (api.variantId.value && variantsById[api.variantId.value]) {
            api.supplierCode.setValue(variantsById[api.variantId.value].manufacturerPartCode);
        }
    }, [api.variantId.value, variantsById]);
    return (
        <>
            <Box gap={16} border rounded padding={16} alignItems='center'>
                <Box>
                    <ProductImage
                        image={
                            api.variantId.value && variantsById[api.variantId.value]
                                ? variantsById[api.variantId.value].image
                                : null
                        }
                        marginRight={20}
                        marginTop={24}
                        size={80}
                    />
                </Box>
                <FormGridRow columns={5}>
                    <VariantSelect api={api.variantId} disabled={isDropship || !supplier} />
                    <LabeledInput
                        value={
                            !api.variantId.value || Object.keys(variantsById).length === 0
                                ? '--'
                                : variantsById[api.variantId.value].productName
                        }
                        disabled
                        label='Product'
                        onChange={() => {}}
                    />
                    <FormInput api={api.supplierCode} label='Supplier code' nullable />
                    <FormNumberInput api={api.warranty} label='Warranty (y)' nullable />
                    <FormDateInput api={api.estimatedReceiveOn} label='Delivery' nullable />

                    <FormNumberInput api={api.quantity} label='Quantity' disabled={isDropship} />
                    <FormPriceInput
                        api={api.unitNetPrice}
                        label='Unit net price'
                        helperText={
                            priceAndTargetState.status === TargetPurchasePriceStatus.PriceIsNet
                                ? `Target price: ${formatPrice(priceAndTargetState.unitNetTargetPrice)}`
                                : undefined
                        }
                    />
                    <FormDecimalInput label='Tax rate' api={api.taxRate} unit='%' precision={2} />
                    <LabeledInput
                        value={formatPrice(totalGrossPrice)}
                        disabled
                        label='Total gross price'
                        onChange={() => {}}
                        helperText={
                            priceAndTargetState.status === TargetPurchasePriceStatus.PriceIsGross
                                ? `Target price: ${formatPrice(priceAndTargetState.totalGrossTargetPrice)}`
                                : undefined
                        }
                    />
                    <FormInput api={api.notes} label='Notes' nullable />
                </FormGridRow>
                {!isDropship && (
                    <Box column>
                        <IconButton icon={SvgIcon.Trash} onClick={() => items.remove(index)} />
                    </Box>
                )}
            </Box>
            <AlertByState
                displayCurrency={currency}
                unitPriceSet={isPositive(api.unitNetPrice.value)}
                priceAndTargetState={priceAndTargetState}
            />
        </>
    );
};

export const calculateTotals = (
    items: ArrayField<PurchaseOrderFormItemData>,
    itemsForm: UseForm<PurchaseOrderItemsFormData>,
    currency: Currency,
) => {
    const totalOrderNetValue = items.fields.reduce((sum, item) => {
        const itemNetPrice = priceToNumber(item.api.unitNetPrice.value) * item.api.quantity.value;
        return sumPrice(sum, {
            amount: itemNetPrice.toString(),
            currency: currency,
        });
    }, zeroPrice(currency));

    const totalTaxValue = items.fields.reduce((sum, item) => {
        const itemTaxValue =
            (percentToNumber(item.api.taxRate.value) / 100) *
            priceToNumber(item.api.unitNetPrice.value) *
            item.api.quantity.value;
        return sumPrice(sum, {
            amount: itemTaxValue.toString(),
            currency: currency,
        });
    }, zeroPrice(currency));

    const totalOrderValue: Price = {
        amount: (
            priceToNumber(totalOrderNetValue) +
            priceToNumber(totalTaxValue) +
            priceToNumber(itemsForm.values.totalHandlingCost)
        ).toString(),
        currency: currency,
    };
    return { totalOrderNetValue, totalTaxValue, totalOrderValue };
};

const calculateItemGrossPrice = (
    item: FormFieldRecord<PurchaseOrderFormItemData>,
    currency: Currency,
): Price => {
    const quantity = item.quantity.value || 0;
    const unitNetPrice = item.unitNetPrice.value || zeroPrice(currency);
    const taxRate = item.taxRate.value || zeroPercent;

    const grossPrice = priceToNumber(unitNetPrice) * quantity * (1 + percentToNumber(taxRate) / 100);

    return {
        amount: grossPrice.toString(),
        currency: currency,
    };
};

enum TargetPurchasePriceStatus {
    NoSelectedSupplier = 'no_selected_supplier',
    NoSelectedVariant = 'no_selected_variant',
    NoExchangeRate = 'no_exchange_rate',
    PriceIsGross = 'price_is_gross',
    PriceIsNet = 'price_is_net',
}

type PriceAndTargetState =
    | {
          status: TargetPurchasePriceStatus.NoSelectedVariant;
      }
    | {
          status: TargetPurchasePriceStatus.NoSelectedSupplier;
      }
    | {
          status: TargetPurchasePriceStatus.NoExchangeRate;
      }
    | {
          status: TargetPurchasePriceStatus.PriceIsGross;
          totalGrossTargetPrice: Price;
          totalGrossPrice: Price;
      }
    | {
          status: TargetPurchasePriceStatus.PriceIsNet;
          unitNetTargetPrice: Price;
          unitNetPrice: Price;
      };

interface PriceAndTargetStateProps {
    supplier: SupplierDetailDto | null;
    variantsById: VariantWithProductAndPriceMap;
    unitNetPrice: Price;
    totalGrossPrice: Price;
    quantity: number;
    variantId: UUID | null;
}

const getPriceAndTargetState = ({
    supplier,
    variantsById,
    unitNetPrice,
    totalGrossPrice,
    quantity,
    variantId,
}: PriceAndTargetStateProps): PriceAndTargetState => {
    if (!supplier) {
        return {
            status: TargetPurchasePriceStatus.NoSelectedSupplier,
        };
    }

    if (!variantId) {
        return {
            status: TargetPurchasePriceStatus.NoSelectedVariant,
        };
    }

    const targetPurchasePrice = variantsById[variantId].targetPurchasePrice;
    if (!targetPurchasePrice) {
        return {
            status: TargetPurchasePriceStatus.NoExchangeRate,
        };
    }

    if (isPurchaseTaxReclaimableCountry(supplier.billingAddress.country)) {
        return {
            status: TargetPurchasePriceStatus.PriceIsNet,
            unitNetTargetPrice: targetPurchasePrice,
            unitNetPrice,
        };
    }

    return {
        status: TargetPurchasePriceStatus.PriceIsGross,
        totalGrossTargetPrice: {
            currency: targetPurchasePrice.currency,
            amount: (priceToNumber(targetPurchasePrice) * quantity).toFixed(2),
        },
        totalGrossPrice,
    };
};

interface AlertByStateProps {
    displayCurrency: Currency;
    unitPriceSet: boolean;
    priceAndTargetState: PriceAndTargetState;
}
const AlertByState: FC<AlertByStateProps> = ({ displayCurrency, unitPriceSet, priceAndTargetState }) => {
    if (
        priceAndTargetState.status === TargetPurchasePriceStatus.NoSelectedVariant ||
        priceAndTargetState.status === TargetPurchasePriceStatus.NoSelectedSupplier
    ) {
        return null;
    }

    if (priceAndTargetState.status === TargetPurchasePriceStatus.NoExchangeRate) {
        return (
            <CompactAlert
                icon={SvgIcon.AlertTriangle}
                type='negative'
                description={`Target purchase price not available: no exchange rate found for ${displayCurrency}`}
            />
        );
    }

    if (!unitPriceSet) {
        return null;
    }

    const { price, target } =
        priceAndTargetState.status === TargetPurchasePriceStatus.PriceIsGross
            ? {
                  price: priceAndTargetState.totalGrossPrice,
                  target: priceAndTargetState.totalGrossTargetPrice,
              }
            : {
                  price: priceAndTargetState.unitNetPrice,
                  target: priceAndTargetState.unitNetTargetPrice,
              };
    return <ItemTablePurchasePriceAlert price={price} targetPrice={target} />;
};

interface ItemTablePurchasePriceAlertProps {
    price: Price;
    targetPrice: Price;
}

const ItemTablePurchasePriceAlert = ({ price, targetPrice }: ItemTablePurchasePriceAlertProps) => {
    const alertProps = usePriceAlertProps(price, targetPrice);
    return <CompactAlert icon={SvgIcon.AlertCircle} {...alertProps} />;
};
const usePriceAlertProps = (
    price: Price,
    targetPrice: Price,
): {
    type: VisualType;
    description: string;
} => {
    const { formatPrice } = usePrice();
    const priceDifference = isPriceGreaterThan(price, targetPrice)
        ? minusPrice(price, targetPrice)
        : minusPrice(targetPrice, price);

    const priceAmount = priceToNumber(price);
    const targetAmount = priceToNumber(targetPrice);
    const percentOfTargetPrice = 100 + (100 * (priceAmount - targetAmount)) / targetAmount;

    if (percentOfTargetPrice < 80) {
        return {
            type: 'warning',
            description: `${formatPrice(priceDifference)} (${percentBelow(
                percentOfTargetPrice,
            )}%) less than the target price`,
        };
    }

    if (percentOfTargetPrice < 100) {
        return {
            type: 'informative',
            description: `${formatPrice(priceDifference)} (${percentBelow(
                percentOfTargetPrice,
            )}%) less than the target price`,
        };
    }

    if (percentOfTargetPrice === 100) {
        return {
            type: 'informative',
            description: `Price matches the target price`,
        };
    }

    return {
        type: 'warning',
        description: `${formatPrice(priceDifference)} (${percentOver(
            percentOfTargetPrice,
        )}%) greater than the target price`,
    };
};

const percentBelow = (num: number) => {
    return numberToPercent(100 - num);
};

const percentOver = (num: number) => {
    return numberToPercent(num - 100);
};
